body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

*::-webkit-scrollbar, *::-webkit-scrollbar-thumb {
    /* width: 20px;
    height: 26px; */
    border-radius: 13px;
    background-clip: padding-box;
    border: 10px solid transparent;
}

*::-webkit-scrollbar, *::-webkit-scrollbar-track {
    width: 26px;
    height: 26px;
    border-radius: 13px;
    background-clip: padding-box;
    border: 20px solid transparent;
}

*::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 0 10px;
}

*::-webkit-scrollbar-track {
    box-shadow: inset 0 0 0 10px;
}

.d-none {
    display: none;
}

.d-block {
    display: block;
}

/* text */
.text-left { text-align: left !important; }
.text-right { text-align: right !important; }
.text-center { text-align: center !important; }

.font-weight-normal { font-weight: normal !important; }
.font-weight-bold { font-weight: bold !important; }
.font-italic { font-style: italic !important; }

.swal-button.green-button {
    background-color: green;
}
.swal-button.green-button:hover {
    background-color: green;
}